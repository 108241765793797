import axios from 'axios';

const headers = {
  'Content-Type': 'application/json'
};
export const instance = axios.create({
  baseURL: process.env.REACT_APP_USERS_API_URL,
  // baseURL: 'https://api.v2.users.detooo.com',
  // baseURL: 'https://api.dev.users.detooo.com',
  headers
});

export const RechargesApi = axios.create({
  baseURL: process.env.REACT_APP_RECHARGES_API_URL,
  // baseURL: 'https://api.v2.recargas.detooo.com',
  // baseURL: 'https://api.dev.recargas.detooo.com',
  headers
});

export const OrdersApi = axios.create({
  baseURL: process.env.REACT_APP_ORDERS_API_URL,
  // baseURL: 'https://api.v2.orders.detooo.com',
  // baseURL: 'https://api.dev.orders.detooo.com',
  headers
});

export const PaymentsApi = axios.create({
  baseURL: process.env.REACT_APP_PAYMENTS_API_URL,
  // baseURL: 'https://api.v2.payments.detooo.com/',
  // baseURL: 'https://api.dev.payments.detooo.com/',
  headers
});

export const DocsApi = axios.create({
  baseURL: process.env.REACT_APP_DOCS_API_URL,
  // baseURL: 'https://api.v2.detooo.com/',
  headers
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;
RechargesApi.CancelToken = axios.CancelToken;
RechargesApi.isCancel = axios.isCancel;
OrdersApi.CancelToken = axios.CancelToken;
OrdersApi.isCancel = axios.isCancel;
PaymentsApi.CancelToken = axios.CancelToken;
PaymentsApi.isCancel = axios.isCancel;

export default instance;
