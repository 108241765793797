import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PhoneIcon from 'src/components/svg/PhoneIcon';
import UserIcon from 'src/components/svg/UserIcon';
import GroupIcon from 'src/components/svg/HandIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0, 4, 3, 4)
  },
  icon: {
    width: 45,
    height: 45
  },
  box: {
    flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
      display: 'block'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  statistic: {
    flexGrow: 1,
    padding: theme.spacing(1),
    alignItems: 'center',

    [theme.breakpoints.down('lg')]: {
      display: 'flex',
      position: 'relative',
      left: '25%'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      position: 'unset',
      justifyContent: 'center',
      '&:not(:last-of-type)': {
        borderRight: `2px solid ${theme.palette.primary.main}`
      }
    }
  }
}));

function Statistics({ className, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation('translation', 'recharge');

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Box mt={4} className={classes.box}>
          <Box className={classes.statistic}>
            <PhoneIcon className={classes.icon} />
            <Box ml={2}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                color="textPrimary"
              >
                1000+
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {t('recharge:Recharges')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.statistic}>
            <UserIcon className={classes.icon} />
            <Box ml={2}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                color="textPrimary"
              >
                200+
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {t('Users')}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.statistic}>
            <GroupIcon className={classes.icon} />
            <Box ml={2}>
              <Typography
                variant="h4"
                component="h2"
                gutterBottom
                color="textPrimary"
              >
                200+
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                {t('Satisfied Clients')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

Statistics.propTypes = {
  className: PropTypes.string
};

export default Statistics;
