import React, { useRef } from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import useSettings from 'src/hooks/useSettings';
import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';

function Languages() {
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const { t, i18n } = useTranslation();

  const handleChangeLanguage = () => {
    const l = settings.language === 'es' ? 'en' : 'es';
    i18n.changeLanguage(l);
    saveSettings({ language: l });
  };

  return (
    <>
      <Tooltip title={t('Language')}>
        <IconButton
          color="primary"
          onClick={handleChangeLanguage}
          ref={ref}
          size="large"
        >
          <SvgIcon fontSize="small">
            <LanguageIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </>
  );
}

export default Languages;
