import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TestimonialsContext = React.createContext({});

export function TestimonialsProvider({ children }) {
  const [testimonials, setTestimonials] = useState([]);
  const [userTestimonials, setUserTestimonials] = useState([]);

  return (
    <TestimonialsContext.Provider
      value={{
        testimonials,
        setTestimonials,
        userTestimonials,
        setUserTestimonials
      }}
    >
      {children}
    </TestimonialsContext.Provider>
  );
}

TestimonialsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default TestimonialsContext;
