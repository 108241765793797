import React from 'react';
import { makeStyles } from '@mui/styles';
import useTestimonials from 'src/hooks/useTestimonials';
import useDataApi from 'src/hooks/useDataApi';
import { getPromotionsV2 } from 'src/services/recharges';
import Page from 'src/components/Page';
import Testimonials from 'src/components/Testimonials';
import Hero from './Hero';
import Statistics from './Statistics';
import CTA from './CTA';
import Promotions from './Promotions';
import Global from './Global';
import Partners from './Partners';

const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();
  const { testimonials } = useTestimonials();
  const [{ loading, total, data: promotions }] = useDataApi(getPromotionsV2, {
    app: 'recargas'
  });

  return (
    <Page className={classes.root} title="Detooo Recargas a Cuba">
      <Hero />
      <Statistics />
      <CTA />
      <Promotions promotions={promotions} loading={loading} total={total} />
      <Global />
      <Partners />
      {testimonials && <Testimonials testimonials={testimonials} />}
    </Page>
  );
}

export default HomeView;
