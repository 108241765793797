import * as React from 'react';

function KeyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={3747}
      height={3747}
      viewBox="0 0 3847 3847"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <g>
        <path d="M2835 942h-260c-10 0-30 0-40 10l-882 771c-40-10-110-20-160-20-351 0-641 281-641 631 0 361 290 642 641 642 350 0 641-281 641-642 0-100-20-170-50-230l220-70c30-10 40-40 40-60v-241l241-20c30 0 60-30 60-60l10-290 190-41c30-10 50-40 50-70v-250c0-40-30-60-60-60zM1302 2334c81 0 141 50 171 111 10 30 20 60 20 90 0 100-90 190-191 190-110 0-190-90-190-190 0-110 80-201 190-201z" />
        <path
          d="M1924 0c531 0 1011 220 1362 561 351 351 561 832 561 1363s-210 1011-561 1362-831 561-1362 561-1012-210-1363-561C220 2935 0 2455 0 1924S220 912 561 561C912 220 1393 0 1924 0zm1132 791c-291-290-692-460-1132-460-441 0-842 170-1133 460-290 291-460 692-460 1133 0 440 170 841 460 1132 291 290 692 471 1133 471 440 0 841-181 1132-471 290-291 471-692 471-1132 0-441-181-842-471-1133z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default KeyIcon;
