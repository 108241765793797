/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CURRENCY } from 'src/constants';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PackageIcon from 'src/components/svg/PackageIcon';
import PackageTwoIcon from 'src/components/svg/PackageTwoIcon';
import PackageThreeIcon from 'src/components/svg/PackageThreeIcon';
import CheckIcon from 'src/components/svg/CheckIcon';

const icons = [PackageThreeIcon, PackageTwoIcon, PackageIcon];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    // boxShadow: 'inset 0px 50px 65px -40px rgba(0, 0, 0, .03)',
    padding: theme.spacing(5, 0)
  },
  card: {
    width: 280,
    height: 450,
    textAlign: 'center',
    '&:hover': {
      boxShadow: '-5px 5px 34px 0 #bcc3d6',
      '&:before': {
        bottom: -6
      }
    }
  },
  icon: {
    marginTop: theme.spacing(5),
    width: 70,
    height: 70
  },
  checkIcon: {
    width: 15,
    height: 15,
    marginRight: 5,
    display: 'block'
  },
  dl: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    textAlign: 'justify'
  },
  button: {
    marginTop: theme.spacing(2)
  },
  gridItem: {
    paddingTop: 40,
    justifyContent: 'center',

    // [theme.breakpoints.down('md')]: {
    //   justifyContent: 'center'
    // },
    [theme.breakpoints.up('md')]: {
      gridGap: theme.spacing(8)
    }
  },
  descriptionBox: {
    height: 140
  }
}));

function Promotions({ promotions, loading, total, className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation('translation', 'recharge');

  const CustomPackageIcon = (i) => {
    const Icon = icons[i];
    return <Icon className={classes.icon} />;
  };

  const handleClick = (dest) => {
    history.push(`/app/recharges/${dest}`);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          component="h4"
          align="center"
          gutterBottom
          color="textPrimary"
        >
          {t('Promotions')}
        </Typography>
        <Typography variant="body1" color="textPrimary" align="center">
          {t('We have exclusive promotions and the best recharge offers!')}
        </Typography>
        <Grid container spacing={3} className={classes.gridItem}>
          {!loading &&
            promotions.map((promotion, idx) => (
              <Grid key={promotion._id} item>
                <Card className={classes.card}>
                  {CustomPackageIcon(idx)}
                  <CardContent>
                    <Typography
                      variant="subtitle2"
                      component="h4"
                      color="textPrimary"
                    >
                      Recibe en Cuba
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="h4"
                      gutterBottom
                      color="textPrimary"
                    >
                      {promotion.titulo}
                    </Typography>
                    <Box className={classes.descriptionBox}>
                      {promotion.caracteristicas.map((description, i) => (
                        <Typography
                          key={i}
                          className={classes.dl}
                          variant="caption"
                          gutterBottom
                          component="div"
                        >
                          <CheckIcon className={classes.checkIcon} />
                          {description}
                        </Typography>
                      ))}
                    </Box>
                    <Typography variant="h4" style={{ paddingTop: 25 }}>
                      {`${promotion.amount} ${DEFAULT_CURRENCY}`}
                    </Typography>
                    <Button
                      color="primary"
                      className={classes.button}
                      variant="contained"
                      component="a"
                      size="large"
                      onClick={() => handleClick(promotion.dest)}
                    >
                      {t('recharge:Recharge')}
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Container>
    </div>
  );
}

Promotions.propTypes = {
  className: PropTypes.string,
  promotions: PropTypes.array,
  loading: PropTypes.bool,
  total: PropTypes.number
};

export default Promotions;
