import * as React from 'react';

function PackageIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={1006}
      height={1260}
      viewBox="0 0 1177 1474"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__fil4{fill:#68acde}.prefix__fil3{fill:#4c99d3}.prefix__fil10{fill:#653f7e}.prefix__fil2{fill:#2775b3}.prefix__fil0{fill:#2466a0}.prefix__fil1{fill:#265c8e}.prefix__fil14{fill:#2e7fc1;fill-rule:nonzero}'
          }
        </style>
      </defs>
      <g id="prefix__Capa_x0020_1">
        <g id="prefix___2521284609952">
          <path
            className="prefix__fil0"
            d="M517 510c-146 14-302-138-288-284 3-31 48-105 73-106-36 31-32 94-11 141 47 105 141 178 262 218l-36 31z"
          />
          <path
            className="prefix__fil1"
            d="M572 517c-52 6-160-18-207-47 31-12 58-26 74-44 35 22 72 39 114 53l19 38z"
          />
          <path
            className="prefix__fil2"
            d="M594 513c-11-42-26-158-43-196-28-66-63-122-100-158-63-60-130-57-165-32-18 13-53 68-56 93-1 2-1 5-1 7 51-71 129-41 175-4 37 30 67 77 94 130 21 39 45 127 65 166l31-6z"
          />
          <path
            className="prefix__fil3"
            d="M514 242c-20-33-41-61-63-83-63-60-130-57-165-32-18 13-53 68-56 93-1 2-1 5-1 7 51-71 129-41 175-4 28 22 52 55 73 91 12-18 23-44 37-72z"
          />
          <path
            className="prefix__fil4"
            d="M386 118c-20-7-40-9-58-7-9 28-16 54-22 73 24 0 47 8 68 19 4-25 8-54 12-85z"
          />
          <path
            className="prefix__fil3"
            d="M600 522c-36-195-227-315-453-205 15 31-8 58 25 81 140-76 274-69 398 117l30 7z"
          />
          <path
            className="prefix__fil4"
            d="M296 275c-23 2-48 6-73 12l15 81c28-9 54-14 81-15-7-25-14-51-23-78z"
          />
          <path
            d="M600 522c-13-67-43-125-87-168-14 12-30 29-47 46 35 26 70 63 104 115l10 20 20-13z"
            fill="#2e7fc1"
          />
          <path
            className="prefix__fil0"
            d="M567 463c-39-151 81-331 225-334 31-1 109 38 116 63-37-33-97-22-139 6-94 62-159 189-176 321l-26-56z"
          />
          <path
            className="prefix__fil1"
            d="M568 515c-14-53-8-162 12-215 17 31 34 58 53 72-20 44-34 100-39 143h-26z"
          />
          <path
            className="prefix__fil2"
            d="M605 523c97-12 228-41 314-161 51-69 40-122-21-185-17-17-75-47-100-48-3 0-5-1-7 0 78 45 89 122 61 174-59 114-183 142-262 199l15 21z"
          />
          <path
            className="prefix__fil0"
            d="M733 141c19-7 39-11 59-12 31-1 109 38 116 63-37-33-97-22-139 6-8 5-17 11-24 17l-12-74z"
          />
          <path
            d="M898 390c7-9 14-18 21-28 51-69 40-122-21-185-17-17-75-47-100-48-3 0-5-1-7 0 78 45 89 122 61 174-10 20-23 37-37 53 26 14 54 26 83 34z"
            fill="#4596d1"
          />
          <path
            className="prefix__fil0"
            d="M833 173c-22 3-45 12-64 25-3 2-5 3-7 5l-14-47s34 4 85 17z"
          />
          <path
            className="prefix__fil4"
            d="M951 287c2-30-10-59-35-90-26-9-51-16-73-22 20 29 26 61 22 90 29 9 59 17 86 22z"
          />
          <path
            className="prefix__fil3"
            d="M667 432c8-12 15-29 19-44 9-28 19-66 19-92-1-54-23-95-41-144-20-52-42-101-57-152-31 23-47 87-72 104 40 103 97 115 116 221 4 22 6 54-10 98l26 9z"
          />
          <path
            className="prefix__fil4"
            d="M661 146c-8-24-18-47-28-71-21 37-41 89-48 111-1 2-1 3-2 4 10 13 20 25 29 39l49-83z"
          />
          <path
            d="M585 519c99-64 122-164 119-234 0-14-3-28-6-39-8 27-26 55-47 74 7 39 1 73-10 103-16 42-44 74-56 96z"
            fill="#3c8bca"
          />
          <path fill="#b49ac9" d="M589 449l581 120-10 767-571 138z" />
          <path fill="#8560a5" d="M589 449L7 555l10 780 572 139z" />
          <path
            className="prefix__fil10"
            d="M589 449l581 120-2 143-579-73zM589 449L7 555l2 146 580-62z"
          />
          <path fill="#a07fb8" d="M589 448l588 121-3 134-585-81z" />
          <path fill="#734f98" d="M589 448L0 555l3 139 586-72z" />
          <path
            d="M583 449l2 173h7l1-173h-10zm2 190l4 835 2-835h-6z"
            fill="#bca7d1"
          />
          <path
            className="prefix__fil14"
            d="M318 501l-97 15-2 868 97 24zM854 502l97 20v864l-97 24z"
          />
        </g>
      </g>
    </svg>
  );
}

export default PackageIcon;
