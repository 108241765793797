import './globals';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom';
import { SettingsProvider } from 'src/context/SettingsContext';
import { UserProvider } from 'src/context/UserContext';
import { TestimonialsProvider } from 'src/context/TestimonialsContext';
import { restoreSettings } from 'src/utils/settings';
import App from 'src/App';
import './i18n';

enableES5();

const settings = restoreSettings();

ReactDOM.render(
  <UserProvider>
    <TestimonialsProvider>
      <SettingsProvider settings={settings}>
        <App />
      </SettingsProvider>
    </TestimonialsProvider>
  </UserProvider>,
  document.getElementById('root')
);
