import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Grid, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      background: `linear-gradient(to right, #007CC7 35%, ${theme.palette.background.default} 35%, rgba(0, 0, 0, .02)) 5%`
    },
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.background.default
    },
    boxShadow: 'inset 0px -10px 50px 0px rgba(0, 0, 0, .03)'
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      background: 'linear-gradient(to left, #008DD2, #007CC7)'
    }
  },
  image: {
    width: '100%',
    marginBottom: -4,
    height: 'auto',
    '& > img': {
      width: '100%'
    }
  },
  button: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.down('lg')]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default
    },

    margin: theme.spacing(4, 2, 4, 0),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.background.default
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 20
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: 150
    }
  },
  heroMessage: {
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.background.default
    },
    padding: '20px 10px 10px 0'
  },
  centered: {
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'start'
    }
  }
}));

function Hero({ className, ...rest }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handleClick = () => {
    history.push('/app/recharges/cubacel');
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            className={clsx(classes.centered, classes.container)}
          >
            <Box>
              <Typography
                variant="h1"
                className={clsx(classes.title, classes.centered)}
                color="textPrimary"
              >
                {t('Top-ups to Cuba')}
              </Typography>
              <Typography
                className={clsx(classes.heroMessage, classes.centered)}
                variant="body1"
                color="textPrimary"
              >
                {t('Hero message')}
              </Typography>
              <Button
                className={clsx(classes.button, classes.centered)}
                variant="contained"
                component="a"
                size="large"
                onClick={handleClick}
              >
                {t('Top-up Now')}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div className={classes.image}>
              <img src="/static/images/banner_1.png" alt="detooo recharges" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
