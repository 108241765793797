import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={3866}
      height={3866}
      viewBox="0 0 3866 3866"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <path
        d="M3866 1933C3866 865 3001 0 1933 0S0 865 0 1933s865 1933 1933 1933 1933-865 1933-1933zm-1103-601h-677V922c9-181-45-337-156-337-197 0-191 159-191 159-35 563-553 738-553 738v1195l67 9c566 0 225 290 1157 290 703 0 718-620 718-1046s-194-598-365-598zM887 2826c79 0 149-66 149-149V1485c0-83-70-150-149-150-83 0-153 67-153 150l4 1192c0 83 66 149 149 149z"
        fill="#008dd2"
      />
    </svg>
  );
}

export default SvgComponent;
