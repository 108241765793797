import React, { useEffect } from 'react';
import Lottie from 'lottie-web';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import animationData from './confetti.json';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}));

function Confetti() {
  const classes = useStyles();

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector('#loading'),
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        width={200}
        height={330}
        justifyContent="center"
        mb={6}
      >
        <div id="loading" />
      </Box>
    </div>
  );
}

export default Confetti;
