import axios, { RechargesApi } from 'src/utils/axios';
import { APP_NAME } from 'src/constants';

export const registerUser = (user) => {
  const param = user;
  param.app = APP_NAME;
  return axios.post('/auth/register', param).then((response) => response);
};

export const login = async ({ username, password }) => {
  return axios
    .post('/auth/login', { username, password })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const updateProfile = (user) => {
  return axios.put('/profile', user);
};

export const getUserById = (id) => {
  return axios.get(`/admin/users/${id}`).then((response) => {
    return response.data;
  });
};

export const getUsersById = (ids) => {
  // const idsWithQuotes = ids.map((id) => `"${id}"`);
  // return axios
  //   .get(`/users/public/list?id=[${idsWithQuotes}]`)
  //   .then((response) => response.data);
  return axios
    .get(`/users/public/list?id=${JSON.stringify(ids)}`)
    .then((response) => {
      return response.data;
    });
};

export const authLogin = (params) => {
  return axios.post('/auth/login/', params).then((response) => {
    return response.data;
  });
};

export const changePassword = (params) => {
  return axios.patch('/profile/change_password', params).then((response) => {
    return response.data;
  });
};

export const recoverPassword = (email) => {
  return axios
    .post('/auth/recover', { email, app: APP_NAME })
    .then((response) => {
      return response.data;
    });
};

export const verifyToken = (token) => {
  return axios.get(`/auth/verify/${token}`).then((response) => {
    return response;
  });
};

export const resetPassword = (token) => {
  return axios.get(`/auth/reset/${token}`).then((response) => {
    return response;
  });
};

export const postResetPassword = (token, password) => {
  return axios.post(`/auth/reset/${token}`, password).then((response) => {
    return response;
  });
};

export const getProfile = () => {
  return axios.get('/profile').then((response) => {
    return response.data;
  });
};

export const changeAvatar = (value) => {
  return axios
    .patch('/profile/update_avatar', value, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then((response) => {
      return response;
    });
};

export const getRechargerProfile = () => {
  return RechargesApi.get('/profile').then((response) => {
    return response;
  });
};

export const fetchCountries = () => {
  return axios
    .get('https://restcountries.eu/rest/v2/all')
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response.statusText);
};

export const isSubscribed = () => {
  return axios
    .get('/subscriptions/subscription/promo_recargas')
    .then((response) => {
      return response.data;
    });
};

export const subscribe = (params) => {
  return axios.post('/subscriptions/', params);
};

export const unsubscribe = (params) => {
  return axios.post('/subscriptions/remove', params);
};

export const checkEmail = (email) => {
  return axios
    .get(`/users/check_email/${email}`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
};

export const checkMovil = (movil) => {
  return axios
    .get(`/users/check_movil/${movil}`)
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return false;
    });
};

export default getUserById;
