import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import useUser from 'src/hooks/useUser';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  popover: {
    width: 200,
    marginTop: 14
  },
  topButton: {
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(0.4),
      marginRight: theme.spacing(0.4)
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  },
  icons: {
    width: 20,
    height: 20,
    color: theme.palette.primary.main,
    margin: theme.spacing(0.5, 1, 0.5, 0)
  }
}));

function Account() {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const { user, logout } = useUser();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      logout();
      history.push('/');
    } catch (error) {
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  if (!user) {
    return (
      <>
        <Button
          className={classes.topButton}
          variant="outlined"
          component="a"
          href="/login"
        >
          {t('Sign In')}
        </Button>
        <Button
          className={classes.topButton}
          variant="outlined"
          color="secondary"
          component="a"
          href="/register"
        >
          {t('Sign Up')}
        </Button>
      </>
    );
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src={user.avatar.replace(/(^\w+:|^)\/\//, '//')}
        />
        <Hidden mdDown>
          <Typography variant="h6" color="inherit">
            {user.fullname}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/app/account">
          <PersonIcon color="" className={classes.icons} />
          {t('Profile')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <img
            className={classes.icons}
            src="/static/images/logout.svg"
            alt="Logout"
          />
          {t('Logout')}
        </MenuItem>
      </Menu>
    </>
  );
}

export default Account;
