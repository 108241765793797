import * as React from 'react';

function MoneyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={3747}
      height={3747}
      viewBox="0 0 3605 3605"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <g>
        <path d="M1801 746c-582 0-1055 473-1055 1055 0 583 473 1055 1055 1055 583 0 1055-472 1055-1055 0-582-472-1055-1055-1055zm123 986c205 0 371 157 371 352 0 193-166 350-371 350h-51v72c0 38-31 70-72 70-38 0-69-32-69-70v-72h-51c-204 0-371-157-371-350 0-40 31-72 69-72 41 0 73 32 73 72 0 117 103 211 229 211h51v-421h-51c-204 0-371-158-371-353s167-353 371-353h51v-69c0-38 31-69 69-69 41 0 72 31 72 69v69h51c205 0 371 158 371 353 0 38-31 69-72 69-38 0-69-31-69-69 0-117-104-211-230-211h-51v422h51zm-472-211c0 116 103 211 229 211h51v-422h-51c-126 0-229 94-229 211zm472 774c126 0 230-94 230-211 0-116-104-210-230-210h-51v421h51z" />
        <path
          d="M1801 0c498 0 951 202 1275 529 328 324 529 775 529 1272 0 498-201 951-529 1275-324 328-777 529-1275 529-494 0-948-201-1272-529C202 2749 0 2299 0 1801c0-497 202-948 529-1272C853 202 1307 0 1801 0zm1061 743c-271-271-645-438-1061-438-412 0-787 167-1058 438s-438 646-438 1058c0 416 167 790 438 1061s646 438 1058 438c416 0 790-167 1061-438s438-645 438-1061c0-412-167-787-438-1058z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default MoneyIcon;
