import * as React from 'react';

function CheckIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={3575}
      height={3575}
      viewBox="0 0 3572 3572"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <path
        d="M1787 0C799 0 0 799 0 1787c0 986 799 1785 1787 1785 986 0 1785-799 1785-1785C3572 799 2773 0 1787 0zm828 1292l-855 1307c-3 0-3 0-3 3-4 6-7 13-13 19-6 9-15 12-25 18-3 4-6 7-9 7-12 9-28 15-43 18-3 0-10 3-16 3-12 3-25 3-37 0-9 0-15 0-25-3-9-3-18-6-25-9-9-3-15-6-21-9s-10-10-13-13c-3 0-3-3-6-3l-499-461c-62-59-68-158-9-220 59-65 158-69 223-10l360 335 752-1153c50-71 146-92 217-46 75 50 93 146 47 217z"
        fill="#008dd2"
      />
    </svg>
  );
}

export default CheckIcon;
