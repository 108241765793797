import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useUser from 'src/hooks/useUser';
import {
  AppBar,
  Box,
  Button,
  Container,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import { MENU } from 'src/constants';
import Settings from './Settings';
import Languages from './Languages';
import Account from './Account';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  container: {
    paddingLeft: 2,
    paddingRight: 2
  },
  logo: {
    width: 130,
    marginTop: 5,
    marginRight: theme.spacing(2)
  },
  logoMovil: {
    width: 36,
    marginTop: 5
  },
  button: {
    color: theme.palette.text.primary.main,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.primary.main,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  title: {
    marginRight: 'auto'
  },
  link: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    paddingTop: 5,
    '& + &': {
      marginLeft: theme.spacing(2),
      textDecoration: 'none'
    }
  },
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: '1.2rem'
  },
  active: {
    color: theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  },
  toolbar: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1)
    }
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user } = useUser();

  const showIcon = (i, j) => {
    const Icon = MENU[i].items[j].icon;
    return <Icon className={classes.icon} />;
  };

  return (
    <AppBar
      id="toolbar"
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Container maxWidth="lg" className={classes.container}>
        <Toolbar disableGutters className={classes.toolbar}>
          <Hidden mdUp>
            {!user ? (
              <RouterLink to="/" arial-label="Go to Home" rel="noreferrer">
                <img
                  className={classes.logoMovil}
                  width="36"
                  src="/static/images/icono_detooo.svg"
                  alt="Detooo Anuncios"
                />
              </RouterLink>
            ) : (
              <IconButton
                className={classes.menuButton}
                color="inherit"
                onClick={onMobileNavOpen}
                size="large"
              >
                <SvgIcon fontSize="small">
                  <MenuIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Hidden>
          <Hidden mdDown>
            <RouterLink to="/" arial-label="Go to Home" rel="noreferrer">
              <img
                className={classes.logo}
                src="/static/images/recargas_marca.svg"
                alt="Detooo Anuncios"
              />
            </RouterLink>
            {user &&
              MENU.map((menu, i) =>
                menu.items.map((m, j) => (
                  <Button
                    key={m.title}
                    activeClassName={classes.active}
                    className={clsx(classes.buttonLeaf, 'depth-0')}
                    component={RouterLink}
                    exact
                    style={{ paddingLeft: 8 }}
                    to={m.href}
                  >
                    {m.icon && showIcon(i, j)}
                    <span className={classes.title}>{t(m.title)}</span>
                  </Button>
                ))
              )}
          </Hidden>
          <Box flexGrow={1} />
          <Settings />
          <Languages />
          <Account />
        </Toolbar>
      </Container>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
