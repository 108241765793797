import { useCallback, useContext } from 'react';
import Context from 'src/context/UserContext';
import authService from 'src/services/authService';
import {
  login as loginService,
  getProfile,
  isSubscribed as checkSubscription
} from 'src/services/users';
import { getProvinceById } from 'src/services/provinces';
import { getMunicipalitiesByProvince } from 'src/services/municipalities';

export default function useUser() {
  const {
    user,
    accessToken,
    updateProfile,
    setUserData,
    setAccessToken,
    setUserProvince,
    setUserMunicipalities,
    setIsSubscribed
  } = useContext(Context);

  const login = useCallback(
    async ({ username, password }) => {
      await loginService({ username, password })
        // eslint-disable-next-line camelcase
        .then(async ({ user, access_token }) => {
          setAccessToken(access_token);
          setUserData(user);
          await getProvinceById(user.provincia).then(setUserProvince);
          await getMunicipalitiesByProvince(user.provincia).then(
            setUserMunicipalities
          );
          authService.setSession(access_token);
          window.localStorage.setItem('accessToken', access_token);

          await checkSubscription().then(setIsSubscribed);
        })
        .catch((error) => {
          window.localStorage.removeItem('accessToken');
          throw error;
        });
    },
    [setAccessToken]
  );

  const loginInWithToken = useCallback(async () => {
    await getProfile()
      .then(async (user) => {
        setUserData(user);
        await getProvinceById(user.provincia).then(setUserProvince);
        await getMunicipalitiesByProvince(user.provincia).then(
          setUserMunicipalities
        );
        await checkSubscription().then(setIsSubscribed);
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('accessToken');
    setAccessToken(null);
    setUserData(null);
  }, [setAccessToken]);

  return {
    isLogged: Boolean(accessToken),
    setUserData,
    setUserProvince,
    setUserMunicipalities,
    setIsSubscribed,
    updateProfile,
    user,
    login,
    loginInWithToken,
    logout
  };
}
