/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import sliceText from 'src/utils/sliceText';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import StarIcon from '@mui/icons-material/Star';
import Pagination from '@mui/material/Pagination';

const TESTIMONIALS_LIMIT = 3;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(8, 2)
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  },
  card: {
    width: 300,
    height: 200,
    marginTop: theme.spacing(4)
  },
  cardContent: {
    textAlign: 'justify',
    padding: theme.spacing(2)
  },
  avatar: {
    color: theme.palette.secondary.contrastText
  },
  gridItem: {
    paddingTop: 40,
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between'
    }
  },
  pagination: {
    justifyContent: 'center'
  },
  starIcon: {
    marginLeft: 5,
    fontSize: 20,
    color: '#ffb400'
  },
  description: {
    whiteSpace: 'pre-wrap',
    fontStyle: 'italic'
  },
  button: {
    marginTop: theme.spacing(2)
  }
}));

function applyPagination(testimonials, page, limit) {
  return testimonials.slice(page * limit, page * limit + limit);
}

function Testimonials({ testimonials, showHeader, className, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [page, setPage] = useState(1);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const paginatedTestimonials = applyPagination(
    testimonials,
    page - 1,
    TESTIMONIALS_LIMIT
  );

  const handleWriteReview = () => {
    history.push('/app/account/testimonials');
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        {showHeader && (
          <>
            <Typography
              variant="h2"
              component="h5"
              align="center"
              color="textPrimary"
              className={classes.title}
              gutterBottom
            >
              {t('Trusted by Thousands of Happy Customer')}
            </Typography>
            <Typography variant="body1" color="textPrimary" align="center">
              {t('Trusted by subtitle')}
            </Typography>
            <Box style={{ display: 'block', textAlign: 'center' }}>
              <Button
                color="secondary"
                className={classes.button}
                variant="outlined"
                component="a"
                onClick={handleWriteReview}
              >
                {t('Write a review')}
              </Button>
            </Box>
          </>
        )}
        <Grid container spacing={3} className={classes.gridItem}>
          {paginatedTestimonials &&
            paginatedTestimonials.map((testimony) => (
              <Grid key={testimony._id} item>
                <Card className={classes.card}>
                  <CardHeader
                    avatar={
                      <Avatar
                        className={classes.avatar}
                        src={testimony.user.avatar.replace(
                          /(^\w+:|^)\/\//,
                          '//'
                        )}
                      />
                    }
                    title={testimony.user.fullname}
                    action={
                      <Box display="flex" alignItems="center" mt={2}>
                        <Typography component="span" variant="body2">
                          {testimony.rating}
                        </Typography>
                        <StarIcon className={classes.starIcon} />
                      </Box>
                    }
                    subheader={testimony.country}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      component="div"
                      variant="body2"
                      className={classes.description}
                      dangerouslySetInnerHTML={{
                        __html: sliceText(testimony.text, 150)
                      }}
                    />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          <Grid item xs={12}>
            {testimonials.length > TESTIMONIALS_LIMIT && (
              <Pagination
                count={Math.ceil(testimonials.length / TESTIMONIALS_LIMIT) || 0}
                size="large"
                color="primary"
                classes={{ ul: classes.pagination }}
                page={page}
                onChange={handlePageChange}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

Testimonials.propTypes = {
  className: PropTypes.string,
  testimonials: PropTypes.array.isRequired,
  showHeader: PropTypes.bool
};

Testimonials.defaultProps = {
  showHeader: true
};

export default Testimonials;
