import axios from 'src/utils/axios';

export const getAllProvinces = () => {
  return axios.get('/provincias').then((response) => {
    return response.data;
  });
};

export const getProvincesNames = () => {
  getAllProvinces().then((response) => {
    const prov = response.data.map((res) => ({ ...res, name: res.nombre }));
    return prov;
  });
};

export const getProvinceById = (id) => {
  return axios.get(`/provincias/${id}`).then((response) => response.data);
};

export const getMunicipalitiesByProvince = (provinceId) => {
  return axios.get(`/municipios/provincia/${provinceId}`).then((response) => {
    return response.data;
  });
};

export default getAllProvinces;
