import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import KeyIcon from 'src/components/svg/KeyIcon';
import GiftIcon from 'src/components/svg/GiftIcon';
import MoneyIcon from 'src/components/svg/MoneyIcon';
import SeparatorIcon from 'src/components/svg/SeparatorIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      background: `linear-gradient(to left, #007CC7 30%, ${theme.palette.background.default} 30%, rgba(0, 0, 0, .02)) 5%`
    },
    boxShadow: 'inset 0px 50px 65px -40px rgba(0, 0, 0, .03)'
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      background: 'linear-gradient(to right, #008DD2, #007CC7)'
    }
  },
  icon: {
    width: 45,
    height: 45,
    paddingRight: theme.spacing(1),

    [theme.breakpoints.down('lg')]: {
      fill: theme.palette.primary.main
    },

    [theme.breakpoints.up('sm')]: {
      fill: theme.palette.background.default
    }
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 5, 2, 5)
    },
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1, 2, 1, 5)
    }
  },
  image: {
    marginBottom: -4,
    width: '100%',
    height: 'auto',
    '& > img': {
      width: '100%'
    }
  },
  sectionSteps: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(1),
      marginLeft: '26%'
    }
  },
  boxItem: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '30%'
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '0'
    }
  },
  line: {
    width: 5,
    height: 30,
    position: 'relative',

    [theme.breakpoints.down('lg')]: {
      fill: theme.palette.primary.main,
      left: '35%'
    },
    [theme.breakpoints.up('sm')]: {
      fill: theme.palette.background.default,
      left: '4%'
    }
  },
  smColor: {
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.background.default
    }
  },
  sectionTitle: {
    [theme.breakpoints.up('sm')]: {
      color: theme.palette.background.default
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: theme.spacing(1)
    },
    textAlign: 'center'
  }
}));

function CTA({ className, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} sm={6}>
            <div className={classes.image}>
              <img alt="Cubacel Recharge" src="/static/images/banner_2.png" />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.container}>
            <Box className={classes.text}>
              <Typography
                className={classes.sectionTitle}
                color="textPrimary"
                variant="h2"
                component="h3"
              >
                {t('Safe, Fast, Easy')}
              </Typography>
              <Box className={classes.sectionSteps}>
                <Typography color="textPrimary" className={classes.smColor}>
                  {t('Send your top-ups in three steps!')}
                </Typography>
                <Box className={classes.boxItem} mt={1}>
                  <KeyIcon className={classes.icon} />
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    color="textPrimary"
                    className={classes.smColor}
                  >
                    {t('Login')}
                  </Typography>
                </Box>
                <SeparatorIcon className={classes.line} />
                <Box className={classes.boxItem}>
                  <GiftIcon className={classes.icon} />
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    color="textPrimary"
                    className={classes.smColor}
                  >
                    {t('Order')}
                  </Typography>
                </Box>
                <SeparatorIcon className={classes.line} />
                <Box className={classes.boxItem}>
                  <MoneyIcon className={classes.icon} />
                  <Typography
                    variant="subtitle1"
                    component="h3"
                    color="textPrimary"
                    className={classes.smColor}
                  >
                    {t('Pay')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

CTA.propTypes = {
  className: PropTypes.string
};

export default CTA;
