import { useEffect, useState, useReducer } from 'react';
import useIsMountedRef from './useIsMountedRef';

const dataServiceReducer = (state, action) => {
  switch (action.type) {
    case 'SERVICE_INIT':
      return { ...state, loading: true, error: false };
    case 'SERVICE_SUCCESS':
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.items,
        total: action.payload.total
      };
    case 'SERVICE_FAILURE':
      return { ...state, loading: false, error: true };
    default:
      throw new Error();
  }
};

const useDataApi = (service, initialParams) => {
  const [state, dispatch] = useReducer(dataServiceReducer, {
    loading: false,
    error: false,
    data: [],
    total: 0
  });

  const isMountedRef = useIsMountedRef();
  const [params, setParams] = useState(initialParams);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    dispatch({ type: 'SERVICE_INIT' });
    service(params)
      .then((response) => {
        if (isMountedRef.current)
          dispatch({ type: 'SERVICE_SUCCESS', payload: response });
      })
      .catch(() => {
        if (isMountedRef.current) dispatch({ type: 'SERVICE_FAILURE' });
      });
  }, [service, params, reload, isMountedRef]);

  return [state, { setParams, setReload }];
};

export default useDataApi;
