import React, { useEffect } from 'react';
import Lottie from 'lottie-web';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import animationData from './Loading_200x330.json';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000
  }
}));

function SplashScreenAnimated() {
  const classes = useStyles();

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector('#loading'),
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="center" mb={6}>
        <div id="loading" />
      </Box>
    </div>
  );
}

export default SplashScreenAnimated;
