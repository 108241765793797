/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Drawer, Hidden, List, ListSubheader } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MENU } from 'src/constants';
import NavItem from './NavItem';

function renderNavItems(tran, { items, ...rest }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes(tran, { acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes(tran, { acc, pathname, item, depth = 0 }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        title={tran(item.title)}
      >
        {renderNavItems(tran, {
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        info={item.info}
        title={tran(item.title)}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    marginTop: 2,
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    borderRight: 0,
    boxShadow: '12px 0px 6px -10px rgba(0, 0, 255, .05)'
  },
  logoMovil: {
    width: 60,
    marginTop: 5
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation('recharge');

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="99%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <img
                className={classes.logoMovil}
                width="36"
                src="/static/images/icono_detooo.svg"
                alt="Detooo Anuncios"
              />
            </RouterLink>
          </Box>
        </Hidden>

        <Box p={2}>
          {MENU.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {t(config.subheader)}
                </ListSubheader>
              }
            >
              {renderNavItems(t, {
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
