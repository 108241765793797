import * as React from 'react';

function PackageThreeIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={2375}
      height={2101}
      viewBox="0 0 2221 1965"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__fil2{fill:#eb748d}.prefix__fil33{fill:#f8d85b}.prefix__fil35{fill:#f5c917}.prefix__fil30{fill:#e6b30c}.prefix__fil26{fill:#dc951d}.prefix__fil29{fill:#dd9c1b}.prefix__fil49{fill:#68acde}.prefix__fil48{fill:#4c99d3}.prefix__fil55{fill:#653f7e}.prefix__fil47{fill:#2775b3}.prefix__fil45{fill:#2466a0}.prefix__fil40{fill:#30405f}.prefix__fil46{fill:#265c8e}.prefix__fil3,.prefix__fil8{fill:#d3272a;fill-rule:nonzero}.prefix__fil3{fill:#c62727}.prefix__fil4,.prefix__fil59,.prefix__fil7{fill:#c02a2a;fill-rule:nonzero}.prefix__fil4,.prefix__fil59{fill:#bf2728}.prefix__fil59{fill:#2e7fc1}'
          }
        </style>
      </defs>
      <g id="prefix__Capa_x0020_1">
        <g id="prefix___2521273950048">
          <path
            d="M1161 433c-42-134-165-268-258-289-39-8-75-8-103 17-13 12-32 31-35 54 148-47 319 102 364 229 9 1 32 8 32-11z"
            fill="#e03138"
            fillRule="nonzero"
          />
          <path
            d="M1087 290c-55-75-125-133-184-146-4-1-8-2-12-2-24 19-39 43-37 67 68 10 135 50 187 101 18-3 32-11 46-20z"
            fill="#e55057"
            fillRule="nonzero"
          />
          <path
            className="prefix__fil2"
            d="M960 168c-8-4-16-9-24-12-27 9-38 34-44 61 9 3 19 7 28 11 1-4 3-8 5-11 9-17 21-33 35-49z"
          />
          <path
            className="prefix__fil3"
            d="M1064 460c-158-5-272-72-315-148-25-45-9-103 59-157-34 39-9 98 15 130 64 88 218 152 327 160-21 4-65 12-86 15z"
          />
          <path
            className="prefix__fil4"
            d="M786 359c-15-14-28-31-37-47-25-45-9-103 59-157-34 39-9 98 15 130 0 1 1 2 2 2-14 27-26 52-39 72z"
          />
          <path
            d="M1153 455c46-13 92-35 134-60 86-55 152-132 155-191 1-37-6-70-41-96-16-12-43-30-70-33 69 61 22 160-45 232-42 45-100 103-152 119 1 8-3 29 19 29z"
            fill="#e95056"
          />
          <path
            d="M1223 430c22-11 43-22 64-35 46-30 86-65 113-101-10-19-34-37-64-55-13 24-31 47-50 68-26 28-57 59-89 84 7 18 16 30 26 39z"
            fill="#ec646d"
            fillRule="nonzero"
          />
          <path
            className="prefix__fil2"
            d="M1312 377c19-12 34-25 49-40-16-14-38-33-57-51-6 7-13 14-18 21-5 5-10 11-16 16 10 21 27 40 42 54z"
          />
          <path
            className="prefix__fil7"
            d="M1100 434c-10-54-8-132 5-195 10-46 26-85 40-109 19-30 44-54 72-69 47-24 118-10 193 54-52-32-114-8-147 15-86 60-129 206-115 309-5 11-40 15-48-5z"
          />
          <path
            className="prefix__fil3"
            d="M1171 96c14-14 30-26 46-35 47-24 118-10 193 54-52-32-114-8-147 15-1 1-2 1-2 1-33-12-64-23-90-35z"
          />
          <path
            className="prefix__fil4"
            d="M1205 68c4-2 8-5 12-7 12-6 26-10 41-11 34 17 59 35 77 51-12 2-24 5-35 10-28-17-60-30-95-43z"
          />
          <path
            className="prefix__fil8"
            d="M1125 433c-1-42 37-85 78-106 37-20 77-29 113-24 73 12 137 49 191 114l-77 54s-69-95-152-104c-19-2-36 0-52 5-60 17-72 66-72 66l-29-5z"
          />
          <path
            d="M1244 309c24-7 49-9 72-6 54 9 104 32 147 69-19 15-51 35-83 47-27-24-63-47-102-52-11-1-22-1-32 0-9-17-12-36-2-58z"
            fill="#dd3137"
            fillRule="nonzero"
          />
          <path
            d="M1375 319c21 8 42 19 60 33-22 14-52 36-75 50-11-8-25-16-38-22 11-17 31-38 53-61z"
            fill="#e85259"
            fillRule="nonzero"
          />
          <path
            className="prefix__fil7"
            d="M959 17l85-17c-26 151-45 256 84 449l-51-14c-64-61-101-137-123-216-19-70-14-140 5-202z"
          />
          <path
            d="M959 17l85-17c-16 93-29 168-8 256-16 13-36 28-55 38-11-25-19-50-27-75-19-70-14-140 5-202z"
            fill="#ca2627"
            fillRule="nonzero"
          />
          <path
            className="prefix__fil8"
            d="M1033 67c-7 46-10 90-6 136-21 7-48 16-71 22l-2-6c-12-46-15-91-9-135l88-17z"
          />
          <path
            d="M1028 104c-1 15-2 29-2 44-19 7-52 20-80 28-3-18-4-36-3-53l85-19z"
            fill="#dd464b"
            fillRule="nonzero"
          />
          <path fill="#47b8ba" d="M1119 493l463 83-40 1227-423 101z" />
          <path
            d="M1119 493l30 6 425 354-3 54-452-379v-35zm172 31l85 15 202 170-2 54-285-239zm278 480l-2 54-448-374v-56l450 376zm-5 152l-2 54-443-371v-56l445 373zm-5 149l-2 54-438-365v-55l440 366zm-5 152l-2 54-433-362v-56l435 364zm-5 151l-2 54-428-358v-56l430 360zm-5 151l-2 44-9 2-414-346v-56l425 356zm-155 81l-270-226v-56l322 269-52 13zm-144 34l-126-105v-55l178 148-52 12z"
            fill="#59bcbf"
          />
          <path fill="#329899" d="M1119 493l-440 70 32 1242 408 99z" />
          <path
            d="M1119 493l-32 5-401 335 1 55 432-362v-33zm-170 27l-82 13-184 155 1 54 265-222zM690 985l2 55 427-359v-55L690 985zm4 153l1 54 424-356v-55l-425 357zm3 151l2 54 420-351v-56l-422 353zm5 152l1 54 416-348v-56l-417 350zm3 151l2 55 412-346v-55l-414 346zm4 152l2 54 408-341v-56l-410 343zm139 95l271-228v-55l-323 270 52 13zm144 34l127-106v-56l-179 150 52 12z"
            fill="#22a0a3"
          />
          <path fill="#319a96" d="M1119 493l463 83-1 47-462-69z" />
          <path fill="#2a7773" d="M1119 493l-440 70 2 47 438-56z" />
          <path fill="#5abdb3" d="M1119 428l473 85-2 101-471-72z" />
          <path fill="#398c83" d="M1119 428l-456 74 5 101 6-1 445-60z" />
          <path
            d="M1113 429l2 114 4-1h4l1-113-5-1-6 1zm2 126l2 1349h2l3-1350h-3l-4 1z"
            fill="#86cbc9"
          />
          <path fill="#b5283a" d="M822 475l69-10 33 1392-68-16z" />
          <path fill="#bb273c" d="M891 465l2 108-68 9-3-106z" />
          <path fill="#e73a5c" d="M1417 481l-67-12-33 1388 66-16z" />
          <path fill="#e94d6c" d="M1350 469l-2 107 66 11 3-106z" />
          <path
            className="prefix__fil26"
            d="M1676 783c-34-18-181-98-142-166 22-37 66-73 94-68-61 75 16 165 104 244l-56-10z"
          />
          <path
            d="M1773 796c-6-154-41-214-95-252-15-10-29-16-51-6-22 9-45 24-64 43-12 10-21 22-29 36 34-44 91-31 130 20 30 40 75 99 68 156l41 3z"
            fill="#f3c219"
          />
          <path
            d="M1668 538c-12-6-24-8-41 0-22 9-45 24-64 43-12 10-21 22-29 36 13-17 28-25 45-27 32-24 67-48 89-52z"
            fill="#f6d30e"
          />
          <path
            className="prefix__fil29"
            d="M1775 808c14-5 102-37 152-74 55-39 71-68 58-96-18-40-67-79-98-73 27 33 51 51 30 90-24 45-71 90-164 137l22 16z"
          />
          <path
            className="prefix__fil30"
            d="M1729 797c-6-56 53-208 111-247 36-24 78 6 105 30 10 10 28 36 35 49-41-51-86-44-143 35-25 35-64 92-86 150l-22-17z"
          />
          <path
            d="M1861 542c15-3 31 2 45 10 6 12 15 26 25 39 2 3 4 5 6 7-13-3-27-1-41 7l-35-63z"
            fill="#f4d300"
          />
          <path
            className="prefix__fil26"
            d="M1813 803c36 8 187 40 202-26 1-5 2-11 2-17 0-7-1-14-2-22-5-26-17-51-34-60 18 96-93 107-216 98l48 27z"
          />
          <path
            d="M1734 796c17-36 149-153 199-158 19-2 33 2 46 21 13 17 26 39 32 62 4 12 6 27 6 39-9-60-42-63-117-25-30 16-87 35-122 65l-44-4z"
            fill="#f4c836"
          />
          <path
            className="prefix__fil33"
            d="M1926 639c3 0 5-1 7-1 5-1 11-1 16 0 11 17 22 48 28 72-13-1-28 4-49 12-1-31-4-64-2-83z"
          />
          <path
            className="prefix__fil29"
            d="M1734 796c11-24 73-83 128-122-8 20-28 65-53 104-11 7-22 14-31 22l-44-4zM1678 823c-26 4-143-1-186-36-4-3-8-8-12-12-5-8-8-17-7-24 5-42 33-91 61-101-13 41-20 55 8 84 12 14 46 31 72 40 30 12 49 22 92 26l-28 23z"
          />
          <path
            className="prefix__fil30"
            d="M1743 790c-8-32-49-92-93-133-38-35-81-56-108-30-20 19-42 48-56 79-6 13-10 27-12 42 26-89 103-73 229 57l40-15z"
          />
          <path
            d="M1609 627c-13-7-25-11-36-11l-58 79c14-5 31-4 50 4 1-5 2-11 5-15 14-25 26-44 39-57z"
            fill="#eec800"
          />
          <path
            className="prefix__fil29"
            d="M1727 793c-7-12-61-111-76-169-17-65 5-89 27-102 33-18 87-24 106-1-50-6-56-4-62 39-6 48 31 173 44 238l-39-5z"
          />
          <path
            className="prefix__fil35"
            d="M1772 799c0-16 11-38 20-65 19-60 42-140 28-187-12-39-56-43-86-42-12 1-36 9-49 13 54-12 69 16 68 111 0 41-5 107-5 169l24 1z"
          />
          <path
            className="prefix__fil33"
            d="M1807 526c-18-20-49-22-73-21-12 1-36 9-49 13 19-4 32-3 43 2 27-3 58-5 75 4 1 0 3 2 4 2z"
          />
          <path
            d="M1772 799c0-16 11-38 20-65 17-50 35-117 32-164-13-15-44-23-76-15 4 18 6 42 5 74 0 41-5 107-5 169l24 1z"
            fill="#f4ba12"
          />
          <path
            d="M1772 799c0-16 11-38 20-65 13-39 27-88 31-130 0-1 0-2-1-3-3-18-36-31-71-25 2 14 2 32 2 53 0 41-5 107-5 169l24 1z"
            fill="#f4aa0e"
          />
          <path
            className="prefix__fil33"
            d="M1633 643c-9-8-18-13-28-18-16 22-35 52-46 72 13 4 26 10 40 19 10-17 24-47 34-73z"
          />
          <path fill="#3670a3" d="M1730 916l474 72-41 869-431 105z" />
          <path fill="#315d8a" d="M1730 916l-450 68 36 871 416 107z" />
          <path
            className="prefix__fil40"
            d="M1730 916l474 72-2 26-472-43zM1730 916l-450 68 2 31 448-44z"
          />
          <path fill="#4e7bab" d="M1729 774l492 75-8 154-482-43h-2z" />
          <path fill="#31547d" d="M1729 774l-466 81 5 153 7-1 454-47z" />
          <path fill="#dbae15" d="M1521 810l-95 17 26 1062 97 26z" />
          <path className="prefix__fil35" d="M1961 808l95 15-28 1067-92 23z" />
          <path
            d="M1723 775l3 186 4-1h3V774h-4l-6 1zm4 197l5 692V971h-2l-3 1z"
            fill="#819bc1"
          />
          <path
            className="prefix__fil45"
            d="M413 1195c-117 12-241-111-230-227 2-25 39-84 59-85-29 25-26 75-9 113 37 84 112 142 209 174l-29 25z"
          />
          <path
            className="prefix__fil46"
            d="M458 1201c-42 4-129-15-166-38 24-10 46-21 59-35 28 17 58 31 91 42l16 31z"
          />
          <path
            className="prefix__fil47"
            d="M475 1198c-9-34-21-127-34-157-23-53-51-98-80-126-51-48-104-46-132-26-15 10-43 54-45 74-1 2-1 4-1 6 41-57 103-33 140-3 30 23 54 61 75 103 17 32 36 102 52 133l25-4z"
          />
          <path
            className="prefix__fil48"
            d="M411 980c-16-26-33-48-50-65-51-48-104-46-132-26-15 10-43 54-45 74-1 2-1 4-1 6 41-57 103-33 140-3 22 17 41 43 58 72 10-14 19-35 30-58z"
          />
          <path
            className="prefix__fil49"
            d="M309 882c-17-6-33-8-46-6-8 22-14 43-18 58 18 0 38 7 54 16 3-21 6-43 10-68z"
          />
          <path
            className="prefix__fil48"
            d="M480 1205c-29-156-182-252-363-165 12 25-6 47 21 65 111-60 219-55 318 94l24 6z"
          />
          <path
            className="prefix__fil49"
            d="M237 1007c-19 2-39 5-58 10l12 65c21-8 43-12 64-13-5-19-11-40-18-62z"
          />
          <path
            d="M480 1205c-10-54-35-101-70-135-11 10-24 23-38 37 28 21 56 51 84 92l8 16 16-10z"
            fill="#2e7fc1"
          />
          <path
            className="prefix__fil45"
            d="M453 1158c-31-121 65-265 180-268 25 0 87 31 92 51-29-26-77-18-111 5-74 49-126 151-140 256l-21-44z"
          />
          <path
            className="prefix__fil46"
            d="M454 1199c-11-43-7-130 10-172 13 25 27 46 42 58-16 35-27 79-31 114h-21z"
          />
          <path
            className="prefix__fil47"
            d="M484 1206c78-10 182-33 251-129 41-56 32-98-17-149-13-13-60-37-80-38h-6c62 36 72 98 49 140-48 91-146 113-209 159l12 17z"
          />
          <path
            className="prefix__fil45"
            d="M586 900c15-6 31-9 47-10 25 0 87 31 92 51-29-26-77-18-111 5-6 4-13 8-19 14l-9-60z"
          />
          <path
            d="M718 1099c6-7 11-14 17-22 41-56 32-98-17-149-13-13-60-37-80-38h-6c62 36 72 98 49 140-8 15-18 29-29 42 20 11 43 20 66 27z"
            fill="#4596d1"
          />
          <path
            className="prefix__fil45"
            d="M666 926c-18 2-36 9-52 20-1 1-3 2-5 3l-11-37s27 3 68 14z"
          />
          <path
            className="prefix__fil49"
            d="M760 1017c2-25-8-48-28-72-21-7-40-13-58-17 16 22 21 48 17 71 24 7 48 13 69 18z"
          />
          <path
            className="prefix__fil48"
            d="M533 1132c7-9 12-22 16-35 6-22 14-52 14-73 0-43-18-76-33-115-15-41-33-81-45-122-24 19-37 70-57 83 31 83 77 92 93 177 3 17 4 43-9 78l21 7z"
          />
          <path
            className="prefix__fil49"
            d="M529 904c-7-19-15-38-23-57-16 30-33 72-39 89 0 1 0 2-1 3 8 11 16 21 24 31l39-66z"
          />
          <path
            d="M468 1202c79-51 97-131 95-187-1-11-3-23-5-31-6 22-21 44-38 59 6 31 1 59-8 82-13 34-34 60-44 77z"
            fill="#3c8bca"
          />
          <path fill="#b49ac9" d="M471 1147l464 95-8 613-456 110z" />
          <path fill="#8560a5" d="M471 1147L6 1231l7 623 458 111z" />
          <path
            className="prefix__fil55"
            d="M471 1147l464 95-1 115-463-59zM471 1147L6 1231l1 117 464-50z"
          />
          <path fill="#a07fb8" d="M471 1145l470 97-3 107-467-65z" />
          <path fill="#734f98" d="M471 1145L0 1231l3 111 468-58z" />
          <path
            d="M466 1147l1 138 4-1 2 1 1-138-3-1-5 1zm2 151l3 667 1-667h-4z"
            fill="#bca7d1"
          />
          <path
            className="prefix__fil59"
            d="M254 1188l-78 11-1 694 78 20zM683 1189l77 15v692l-78 18z"
          />
        </g>
      </g>
    </svg>
  );
}

export default PackageThreeIcon;
