import * as React from 'react';

function SeparatorIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={106}
      height={589}
      viewBox="0 0 4 20"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <path fillRule="nonzero" d="M1 0h3v20H0V0z" />
    </svg>
  );
}

export default SeparatorIcon;
