import { useCallback, useContext } from 'react';
import Context from 'src/context/TestimonialsContext';
import useUser from 'src/hooks/useUser';
import { getUsersById } from 'src/services/users';
import { getTestimonials, getUserTestimonials } from 'src/services/recharges';

export default function useTestimonials() {
  const { user } = useUser();

  const {
    testimonials,
    userTestimonials,
    setTestimonials,
    setUserTestimonials
  } = useContext(Context);

  const fetchTestimonials = useCallback(async () => {
    await getTestimonials()
      .then(async (response) => {
        if (response.length) {
          const usersId = response.map((testimony) => testimony.user);
          const users = await getUsersById(usersId);

          response.forEach((testimony, index) => {
            testimony.user =
              users && users.length
                ? users.find((user) => user._id === testimony.user)
                : { fullname: '', avatar: '' };
          });

          setTestimonials(response);
        }
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  const fetchUserTestimonials = useCallback(async () => {
    await getUserTestimonials()
      .then((response) => {
        response.forEach((testimony) => {
          testimony.user = {
            fullname: user.fullname,
            avatar: user.avatar
          };
        });
        setUserTestimonials(response);
      })
      .catch((error) => {
        throw error;
      });
  }, []);

  return {
    testimonials,
    userTestimonials,
    setUserTestimonials,
    fetchTestimonials,
    fetchUserTestimonials
  };
}
