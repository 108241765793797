import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useDataApi from 'src/hooks/useDataApi';
import { getDocs } from 'src/services/recharges';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  IconButton,
  Container,
  Grid,
  Typography,
  Link
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FacebookIcon from 'src/components/svg/Facebook';
import Subscription from './Subscription';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    backgroundColor: theme.palette.background.dark,
    boxShadow: 'inset 0px 50px 65px -40px rgba(0, 0, 0, .08)',
    position: 'relative',
    bottom: 0
  },
  logo: {
    marginTop: 20,
    width: 130
    // margin: theme.spacing(2, 2, 2, 0)
  },
  social: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'start'
    }
  },
  button: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 6px 6px 2px rgba(0, 0, 0, .05)'
  },
  footerFirstImg: {
    '& > img': {
      marginTop: 20
    }
  },
  footerImg: {
    height: 28
  },
  footerLink: {
    textDecoration: 'none',
    display: 'block',
    '& > img': {
      marginTop: theme.spacing(1.5)
    }
  },
  centered: {
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'start'
    }
  },
  copyright: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    color: 'white',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    height: 64
  }
}));

function Footer({ className, showSubscription, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [subscriptionState, setSubscriptionState] = useState(null);
  const [{ data: docs, loading }] = useDataApi(getDocs);

  useEffect(() => {
    setSubscriptionState(showSubscription);
  }, [showSubscription]);

  return (
    <footer
      className={clsx(classes.root, className, classes.centered)}
      {...rest}
    >
      <Container maxWidth="lg">
        {subscriptionState && (
          <Subscription setSubscriptionState={setSubscriptionState} />
        )}
        <Grid container justifyContent="center" spacing={5}>
          <Grid item xs={12} sm={3}>
            <RouterLink to="/" arial-label="Go to Home" rel="noreferrer">
              <img
                className={classes.logo}
                src="/static/images/recargas_marca.svg"
                alt="Detooo Anuncios"
              />
            </RouterLink>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{
                marginTop: 10,
                textAlign: 'justify',
                padding: '15px, 15px, 15px, 0'
              }}
              component="p"
            >
              DETÓ Recargas es una sucursal de negocios pertenecientes a la
              empresa DETÓ. Nos especializamos en el envío de recargas de
              celulares hacia Cuba. Compre en línea las ofertas de su
              preferencia y nosotros le hacemos llegar las recargas con
              garantía.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ paddingTop: 20 }}
            >
              {t('Information')}
            </Typography>
            <Link
              className={classes.footerLink}
              component={RouterLink}
              to="/about"
              variant="caption"
              color="textSecondary"
            >
              {t('About Us')}
            </Link>
            <Link
              className={classes.footerLink}
              component={RouterLink}
              to="/contact-us"
              variant="caption"
              color="textSecondary"
            >
              {t('Contact Us')}
            </Link>
            {!loading &&
              docs &&
              docs.map((doc) => (
                <Link
                  key={doc._id}
                  className={classes.footerLink}
                  component={RouterLink}
                  to={`/docs/${doc.title.replace(/\s/g, '-')}`}
                  variant="caption"
                  color="textSecondary"
                >
                  {t(doc.title)}
                </Link>
              ))}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              style={{ paddingTop: 20 }}
            >
              {t('Contact Us')}
            </Typography>
            <Typography variant="caption" component="p" color="textSecondary">
              +1 (786) 203 7678
            </Typography>
            <Typography variant="caption" component="p" color="textSecondary">
              +1 (702) 290 8661
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Link
              className={classes.footerFirstImg}
              href="https://anuncios.detooo.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.footerImg}
                src="/static/images/deto_anuncios.svg"
                alt="Detooo Anuncios"
              />
            </Link>
            <Link
              className={classes.footerLink}
              href="https://shop.detooo.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.footerImg}
                src="/static/images/deto_combos.svg"
                alt="Detooo Combos"
              />
            </Link>
            <Link
              className={classes.footerLink}
              href="https://www.exeditec.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={classes.footerImg}
                src="/static/images/deto_envios.svg"
                alt="Detooo Envíos"
              />
            </Link>
          </Grid>
        </Grid>
        <Box className={classes.social}>
          <Link
            href="https://www.facebook.com/detooopp"
            target="_blank"
            rel="noreferrer"
          >
            <IconButton
              className={classes.button}
              aria-label="facebook"
              size="large"
            >
              <FacebookIcon />
            </IconButton>
          </Link>
        </Box>
      </Container>
      <Box className={classes.copyright}>
        <Typography variant="caption">
          {`${t('Designed by')} `}
          <Link
            color="inherit"
            target="_blank"
            rel="noopener"
            href="https://www.exeditec.com"
          >
            EXEDITEC
          </Link>
          {` © 2021 ${t('All Rights Reserved')}`}
        </Typography>
      </Box>
    </footer>
  );
}

Footer.propTypes = {
  className: PropTypes.string,
  showSubscription: PropTypes.bool
};

Footer.defaultOptions = {
  showSubscription: true
};

export default Footer;
