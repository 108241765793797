import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={3863}
      height={3863}
      viewBox="0 0 3863 3863"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <path
        d="M3863 1932C3863 865 2998 0 1932 0 865 0 0 865 0 1932c0 1066 865 1931 1932 1931 1066 0 1931-865 1931-1931zm-1421-852c0-271-236-508-510-508-272 0-511 237-511 508 0 275 239 511 511 511 274 0 510-236 510-511zm-510 681c-447 0-852 483-852 1083 0 601 1703 601 1703 0 0-600-402-1083-852-1083h1z"
        fill="#008dd2"
      />
    </svg>
  );
}

export default SvgComponent;
