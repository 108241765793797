import React, { useState } from 'react';
import PropTypes from 'prop-types';

const UserContext = React.createContext({});

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(() =>
    window.localStorage.getItem('accessToken')
  );

  const setProvince = (provinceData) => {
    setUser((prevUserData) => ({ ...prevUserData, province: provinceData }));
  };

  const setMunicipalities = (municipalitiesData) => {
    setUser((prevUserData) => ({
      ...prevUserData,
      municipalities: municipalitiesData
    }));
  };

  const setIsSubscribed = (isSubscribed) => {
    setUser((prevUserData) => ({
      ...prevUserData,
      isSubscribed
    }));
  };

  const updateProfile = (data) => {
    setUser((prevUserData) => ({
      ...prevUserData,
      ...data
    }));
  };

  return (
    <UserContext.Provider
      value={{
        user,
        accessToken,
        setIsSubscribed,
        updateProfile,
        setUserData: setUser,
        setUserProvince: setProvince,
        setUserMunicipalities: setMunicipalities,
        setAccessToken
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default UserContext;
