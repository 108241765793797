import React from 'react';
import useUser from 'src/hooks/useUser';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuestGuard({ children }) {
  const { user } = useUser();

  if (user) {
    return <Redirect to="/account" />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
