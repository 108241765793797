import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import GlobalSvg from 'src/components/svg/Global';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    // boxShadow: 'inset 0px 50px 65px -40px rgba(0, 0, 0, .03)',
    padding: theme.spacing(5, 2)
  },
  logo: {
    width: 85,
    marginLeft: 12
  }
}));

function Global({ className, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          gutterBottom
          color="textPrimary"
          component="h5"
        >
          {t('Every second, someone, somewhere sends recharge with')}
          <img
            className={classes.logo}
            src="/static/images/deto_marca.svg"
            alt="Detooo Anuncios"
          />
        </Typography>
        <Box mt={8} display="flex" justifyContent="center">
          <GlobalSvg width={800} height={400} />
        </Box>
      </Container>
    </div>
  );
}

Global.propTypes = {
  className: PropTypes.string
};

export default Global;
