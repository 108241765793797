import * as React from 'react';

function PhoneIconv2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={3877}
      height={3877}
      viewBox="0 0 3877 3877"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <path
        d="M3877 1938C3877 868 3009 0 1938 0 868 0 0 868 0 1938c0 1071 868 1939 1938 1939 1071 0 1939-868 1939-1939zM2533 3300c95 0 172-78 172-173V741c0-95-77-172-172-172H1335c-86 0-163 77-163 172v2386c0 95 77 173 163 173h1198zm-681-267c0-52 43-95 95-95s95 43 95 95c0 51-43 94-95 94s-95-43-95-94zm423-2292c51 0 86 43 86 86 0 52-35 86-86 86h-164c-52 0-86-34-86-86 0-43 34-86 86-86h164zm-423 86c0 52-43 86-86 86-52 0-86-34-86-86 0-43 34-86 86-86 43 0 86 43 86 86zm-517 259h1198v1705H1335V1086z"
        fill="#008dd2"
      />
    </svg>
  );
}

export default PhoneIconv2;
