/* eslint-disable new-cap */
import { DocsApi, OrdersApi, RechargesApi } from '../utils/axios';
import { APP_NAME, PHONE_STORAGE_TIME } from '../constants';

export const getPackages = ({ type }) => {
  return RechargesApi.get('/packages/filterbydest?dest=recargas')
    .then((response) => {
      const packages = response.data.items.filter(
        (pack) => pack.isActive && pack.type === type
      );
      return { total: packages.length, items: packages };
    })
    .catch((error) => {
      throw new Error(error.response.statusText);
    });
};

export const getPromotions = () => {
  return RechargesApi.get('/promotions')
    .then((response) => {
      return { total: response.data.length, items: response.data };
    })
    .catch((error) => {
      throw new Error(error.response.statusText);
    });
};

export const getDocs = () => {
  return DocsApi.get('/docs/filter?app=recargas')
    .then((response) => {
      return { total: response.data.length, items: response.data };
    })
    .catch((error) => {
      throw new Error(error.response.statusText);
    });
};

export const filterDocs = ({ title }) => {
  return DocsApi.get(`/docs/filter?app=recargas&title=${title}`)
    .then((response) => response.data)
    .catch((error) => {
      throw new Error(error.response.statusText);
    });
};

export const getPromotionsV2 = ({ app, dest }) => {
  return RechargesApi.get('/promotions')
    .then((response) => {
      const promos = response.data.filter((promo) => {
        if (dest)
          return (
            promo.app.toLowerCase() === app.toLowerCase() &&
            promo.dest.toLowerCase() === dest.toLowerCase()
          );
        return promo.app.toLowerCase() === app.toLowerCase();
      });
      return { total: promos.length, items: promos };
    })
    .catch((error) => {
      throw new Error(error.response.statusText);
    });
};

export const cubacelRecharge = (values) => {
  const params = values;
  params.app = APP_NAME;
  return RechargesApi.post('recargas/cubacel', params);
};

export const nautaRecharge = (values) => {
  const params = values;
  params.app = APP_NAME;
  return RechargesApi.post('recargas/nauta', params);
};

export const getOrders = ({ queryUrl, page, limit }) => {
  const url = !queryUrl
    ? `page=${page}&limit=${limit}`
    : `${queryUrl}&page=${page}&limit=${limit}`;
  return OrdersApi.get(`orders/user?app=recargas&${url}`).then((response) => {
    return { total: response.data.Total, items: response.data.items || [] };
  });
};

export const filterRecharges = ({ queryUrl, page, limit }) => {
  return RechargesApi.get(
    `recargas/detooo/client/filter?${queryUrl}&page=${page}&limit=${limit}`
  )
    .then((response) => {
      return { total: response.data.Total, items: response.data.recargas };
    })
    .catch((error) => error.response.statusText);
};

export const getTestimonials = () => {
  return RechargesApi.get('testimonials/list').then(
    (response) => response.data
  );
};

export const getUserTestimonials = () => {
  return RechargesApi.get('testimonials/user_list').then(
    (response) => response.data
  );
};

export const addTestimony = (testimony) => {
  return RechargesApi.post('/testimonials', testimony);
};

export const checkRecharges = (recharges) => {
  return RechargesApi.post('recharges/check', recharges);
};

export const isAvailableAccountToRecharge = (value) => {
  const prevPhones = localStorage.getItem('phones');
  if (prevPhones) {
    const phoneExist = prevPhones.find((p) => p.number === value);

    if (phoneExist) {
      const diff = Math.floor((new Date.now() - phoneExist.timestamp) / 1000);
      if (diff > PHONE_STORAGE_TIME) {
        const newPhones = prevPhones.filter((item) => item.phone !== value);
        localStorage.setItem('phones', JSON.stringify(newPhones));
      }
    } else {
      return false;
    }
  }
  return true;
};

export const handleMovilStorage = (phone) => {
  const prevPhones = localStorage.getItem('phones');
  if (!prevPhones) localStorage.setItem('phones', []);

  localStorage.setItem('phones', [
    ...prevPhones,
    JSON.stringify({ phone, timestamp: new Date.now() })
  ]);
};

export default getPackages;
