import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SplashScreen from 'src/components/SplashScreenAnimated';
import useUser from 'src/hooks/useUser';
import useTestimonials from 'src/hooks/useTestimonials';
import authService from 'src/services/authService';

function Auth({ children }) {
  const { logout, loginInWithToken } = useUser();
  const { fetchTestimonials } = useTestimonials();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      authService.setAxiosInterceptors({
        onLogout: () => logout
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        try {
          await loginInWithToken();
        } catch (error) {
          console.error(error);
          logout();
        }
      }

      setLoading(false);
    };

    const testimonials = async () => {
      fetchTestimonials();
    };

    testimonials();
    initAuth();
  }, []);

  if (isLoading) {
    return <SplashScreen />;
  }

  return children;
}

Auth.propTypes = {
  children: PropTypes.any
};

export default Auth;
