import axios from 'src/utils/axios';

export const getMunicipalitiesByProvince = province => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  return axios
    .get(`/municipios/provincia/${province}`, {
      cancelToken: source.token
    })
    .then(response => {
      return response.data;
    });
};

export const getMunicipalities = () => {
  const { CancelToken } = axios;
  const source = CancelToken.source();
  return axios
    .get('/municipios/', {
      cancelToken: source.token
    })
    .then(response => {
      return response.data;
    });
};

export default getMunicipalities;
