/* eslint-disable import/prefer-default-export */

import WifiIcon from '@mui/icons-material/Wifi';
import PhoneIcon from '@mui/icons-material/PhoneIphoneOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/History';

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const DEFAULT_CURRENCY = 'USD';
export const INITIAL_PAGE = 0;
export const DEFAULT_LIMIT = 10;

// time in seconds
export const PHONE_STORAGE_TIME = 3 * 60;
export const APP_NAME = 'recargas';

export const MENU = [
  {
    subheader: 'Recharges',
    items: [
      {
        title: 'Cubacel',
        icon: PhoneIcon,
        href: '/app/recharges/cubacel'
      },
      {
        title: 'Nauta',
        icon: WifiIcon,
        href: '/app/recharges/nauta'
      },

      {
        title: 'History',
        icon: HistoryOutlinedIcon,
        href: '/app/history'
      }
    ]
  }
];
