import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Container, Grid, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const options = {
  loop: true,
  dots: false,
  autoplayHoverPause: true,
  autoplay: true,
  responsive: {
    0: {
      items: 3
    },
    576: {
      items: 3
    },
    768: {
      items: 4
    },
    1200: {
      items: 6
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 2),
    backgroundColor: theme.palette.background.dark
  }
}));

const Partners = ({ className }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <OwlCarousel className="owl-theme" {...options}>
            <Grid item>
              <Link
                href="http://www.exeditec.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/static/images/marca_anuncios.svg"
                  alt="Detooo Anuncios"
                  width={150}
                  height={72}
                />
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="http://www.condirico.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/static/images/marca_condirico.svg"
                  alt="Detooo CondiRico"
                  width={150}
                  height={72}
                />
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="http://www.exeditec.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/static/images/marca_envios.svg"
                  alt="Detooo Envios"
                  width={150}
                  height={72}
                />
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="http://exeditec.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/static/images/marca_exeditec.svg"
                  alt="EXEDITEC"
                  width={150}
                  height={72}
                />
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="http://shop.detooo.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/static/images/marca_combos.svg"
                  alt="Detooo Envíos"
                  width={150}
                  height={72}
                />
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="https://speakslow.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="/static/images/marca_speakslow.svg"
                  alt="Speakslow"
                  width={150}
                  height={72}
                />
              </Link>
            </Grid>
          </OwlCarousel>
        </Grid>
      </Container>
    </div>
  );
};

Partners.propTypes = {
  className: PropTypes.string
};

export default Partners;
