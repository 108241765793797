import * as React from 'react';

function PackageTwoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={1799}
      height={1599}
      viewBox="0 0 1933 1717"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__fil7{fill:#f8d85b}.prefix__fil9{fill:#f5c917}.prefix__fil4{fill:#e6b30c}.prefix__fil0{fill:#dc951d}.prefix__fil3{fill:#dd9c1b}.prefix__fil23{fill:#68acde}.prefix__fil22{fill:#4c99d3}.prefix__fil29{fill:#653f7e}.prefix__fil21{fill:#2775b3}.prefix__fil19{fill:#2466a0}.prefix__fil14{fill:#30405f}.prefix__fil20{fill:#265c8e}.prefix__fil33{fill:#2e7fc1;fill-rule:nonzero}'
          }
        </style>
      </defs>
      <g id="prefix__Capa_x0020_1">
        <g id="prefix___2521273965056">
          <path
            className="prefix__fil0"
            d="M1307 319c-39-20-208-112-163-190 24-43 75-84 107-79-69 86 19 190 120 280l-64-11z"
          />
          <path
            d="M1418 334c-7-176-47-246-109-289-17-12-34-19-59-7-25 11-51 28-74 49-12 12-23 26-32 42 39-50 104-36 149 23 34 45 86 114 78 179l47 3z"
            fill="#f3c219"
          />
          <path
            d="M1298 38c-14-7-28-9-48 0-25 11-51 28-74 49-12 12-23 26-32 42 14-19 32-29 51-31 37-28 77-55 103-60z"
            fill="#f6d30e"
          />
          <path
            className="prefix__fil3"
            d="M1420 349c16-6 118-43 175-85 63-46 81-80 66-111-20-46-77-91-112-84 31 38 58 59 35 104-28 52-82 103-189 156l25 20z"
          />
          <path
            className="prefix__fil4"
            d="M1368 335c-7-64 60-238 127-284 41-27 89 8 120 36 12 11 32 41 41 56-47-59-99-51-165 40-28 40-73 105-98 172l-25-20z"
          />
          <path
            d="M1519 42c17-3 35 3 52 12 7 14 17 30 29 45 2 3 4 5 7 8-15-4-31-2-48 8l-40-73z"
            fill="#f4d300"
          />
          <path
            className="prefix__fil0"
            d="M1464 342c41 9 214 47 232-29 1-6 3-13 3-20 0-8-2-16-4-25-5-30-19-59-38-70 21 111-107 124-248 114l55 30z"
          />
          <path
            d="M1373 335c20-42 172-177 229-182 22-3 38 2 53 24 14 20 29 45 37 71 4 14 7 31 7 45-11-68-49-72-135-29-35 19-100 40-140 75l-51-4z"
            fill="#f4c836"
          />
          <path
            className="prefix__fil7"
            d="M1594 154c3-1 5-1 8-1 6-1 12-1 18 0 12 19 25 55 32 83-14-1-32 4-56 13-1-35-5-74-2-95z"
          />
          <path
            className="prefix__fil3"
            d="M1373 335c13-28 84-96 147-141-9 23-32 74-61 120-13 7-24 16-35 25l-51-4zM1309 366c-30 4-164-2-214-42-4-4-9-9-13-14-6-9-10-19-8-28 6-47 37-104 70-116-16 48-23 64 9 97 14 16 53 35 82 46 35 13 57 26 106 30l-32 27z"
          />
          <path
            className="prefix__fil4"
            d="M1384 327c-9-36-56-105-107-152-44-40-93-64-124-34-23 22-49 54-65 90-6 15-11 31-14 48 30-102 119-84 263 66l47-18z"
          />
          <path
            d="M1230 140c-15-8-29-12-42-12l-66 90c16-5 35-4 57 5 1-6 3-12 6-18 16-28 30-50 45-65z"
            fill="#eec800"
          />
          <path
            className="prefix__fil3"
            d="M1365 331c-8-14-70-128-87-195-20-74 6-102 31-117 38-20 100-27 121 0-57-8-64-5-71 44-6 55 36 199 51 274l-45-6z"
          />
          <path
            className="prefix__fil9"
            d="M1416 338c0-19 14-44 24-74 22-70 48-162 32-215-14-46-64-51-99-49-14 1-42 10-56 15 62-13 79 19 78 128 0 46-6 123-5 193l26 2z"
          />
          <path
            className="prefix__fil7"
            d="M1457 25c-20-23-56-26-84-25-14 1-42 10-56 15 22-4 37-4 49 2 32-3 67-5 86 5 2 0 4 2 5 3z"
          />
          <path
            d="M1416 338c0-19 14-44 24-74 19-59 40-135 37-190-16-16-51-25-88-16 5 21 7 48 6 85 0 46-6 123-5 193l26 2z"
            fill="#f4ba12"
          />
          <path
            d="M1416 338c0-19 14-44 24-74 15-46 31-102 35-150v-4c-4-20-42-36-82-29 2 17 2 37 2 62 0 46-6 123-5 193l26 2z"
            fill="#f4aa0e"
          />
          <path
            className="prefix__fil7"
            d="M1258 158c-11-8-21-15-32-20-19 25-41 59-54 82 15 5 30 13 46 23 11-20 28-55 40-85z"
          />
          <path fill="#3670a3" d="M1369 473l544 82-47 997-495 121z" />
          <path fill="#315d8a" d="M1369 473l-517 77 41 1000 478 123z" />
          <path
            className="prefix__fil14"
            d="M1369 473l544 82-2 29-542-49zM1369 473l-517 77 2 36 515-51z"
          />
          <path fill="#4e7bab" d="M1368 309l565 86-10 177-553-49h-2z" />
          <path fill="#31547d" d="M1368 309l-535 93 5 175h8l522-54z" />
          <path fill="#dbae15" d="M1129 350l-110 20 31 1220 111 30z" />
          <path className="prefix__fil9" d="M1633 348l110 17-32 1225-106 27z" />
          <path
            d="M1361 310l3 214 5-1h4V309h-5l-7 1zm4 226l6 795V535h-2l-4 1z"
            fill="#819bc1"
          />
          <path
            className="prefix__fil19"
            d="M475 832c-135 14-277-127-265-260 3-29 45-96 68-98-34 29-30 86-11 130 44 97 129 163 241 199l-33 29z"
          />
          <path
            className="prefix__fil20"
            d="M525 839c-48 5-147-17-190-44 28-11 53-24 68-40 32 20 66 36 105 48l17 36z"
          />
          <path
            className="prefix__fil21"
            d="M545 835c-10-39-23-145-39-180-26-60-58-112-92-145-58-55-119-53-151-29-17 12-49 62-52 85-1 2-1 5-1 7 47-66 119-38 161-4 34 27 62 71 86 119 19 36 42 116 59 153l29-6z"
          />
          <path
            className="prefix__fil22"
            d="M472 586c-19-30-38-56-58-76-58-55-119-53-151-29-17 12-49 62-52 85-1 2-1 5-1 7 47-66 119-38 161-4 25 20 47 50 67 84 11-18 21-41 34-67z"
          />
          <path
            className="prefix__fil23"
            d="M354 472c-18-6-37-8-53-6-8 26-15 50-20 67 22 0 43 7 63 18 3-24 7-50 10-79z"
          />
          <path
            className="prefix__fil22"
            d="M551 843c-34-179-208-289-416-188 13 28-8 54 23 74 128-69 252-63 365 107l28 7z"
          />
          <path
            className="prefix__fil23"
            d="M272 617c-22 1-44 5-67 11l14 74c25-8 49-13 73-14-5-23-12-47-20-71z"
          />
          <path
            d="M551 843c-12-61-40-115-81-154-12 12-27 26-43 42 33 24 65 59 96 105l9 20 19-13z"
            fill="#2e7fc1"
          />
          <path
            className="prefix__fil19"
            d="M521 790c-37-139 74-305 206-308 28 0 100 35 106 59-34-31-89-21-127 5-86 57-146 174-161 294l-24-50z"
          />
          <path
            className="prefix__fil20"
            d="M521 837c-13-49-7-149 11-197 16 28 31 53 49 66-18 40-31 91-35 131h-25z"
          />
          <path
            className="prefix__fil21"
            d="M555 844c90-11 209-37 289-147 47-64 36-113-20-171-15-15-68-43-92-44h-6c71 42 82 112 56 160-55 105-168 131-240 183l13 19z"
          />
          <path
            className="prefix__fil19"
            d="M673 493c17-6 35-10 54-11 28 0 100 35 106 59-34-31-89-21-127 5-8 5-15 10-22 16l-11-69z"
          />
          <path
            d="M824 722c7-8 13-16 20-25 47-64 36-113-20-171-15-15-68-43-92-44h-6c71 42 82 112 56 160-9 19-21 35-34 49 24 13 50 23 76 31z"
            fill="#4596d1"
          />
          <path
            className="prefix__fil19"
            d="M765 523c-21 3-42 11-59 23-3 2-5 3-7 4l-12-42s31 3 78 15z"
          />
          <path
            className="prefix__fil23"
            d="M873 628c2-28-9-55-32-83-24-8-47-15-67-20 18 26 24 56 20 82 27 8 54 16 79 21z"
          />
          <path
            className="prefix__fil22"
            d="M612 760c8-10 14-26 18-40 8-25 17-60 17-84-1-50-21-87-38-132-18-48-38-93-52-140-28 21-43 80-66 96 37 94 89 105 107 202 4 20 5 50-10 90l24 8z"
          />
          <path
            className="prefix__fil23"
            d="M607 498c-8-22-17-43-26-65-19 34-37 82-44 102-1 1-1 2-1 3 9 13 18 24 26 36l45-76z"
          />
          <path
            d="M537 840c91-58 112-150 109-215 0-12-3-25-5-35-7 25-24 50-44 68 7 36 1 67-9 94-15 39-40 68-51 88z"
            fill="#3c8bca"
          />
          <path fill="#b49ac9" d="M540 777l534 109-9 705-525 126z" />
          <path fill="#8560a5" d="M540 777L7 873l8 716 525 128z" />
          <path
            className="prefix__fil29"
            d="M540 777l534 109-2 132-532-68zM540 777L7 873l1 135 532-58z"
          />
          <path fill="#a07fb8" d="M540 775l540 111-3 123-537-74z" />
          <path fill="#734f98" d="M540 775L0 873l3 129 537-67z" />
          <path
            d="M536 777l1 158h6l2-158-5-1-4 1zm1 174l3 766 2-766-2-1-3 1z"
            fill="#bca7d1"
          />
          <path
            className="prefix__fil33"
            d="M292 824l-90 13-1 797 89 22zM784 825l89 18v794l-89 21z"
          />
        </g>
      </g>
    </svg>
  );
}

export default PackageTwoIcon;
