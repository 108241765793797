import * as React from 'react';

function GiftIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={3747}
      height={3747}
      viewBox="0 0 3747 3747"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      fillRule="evenodd"
      clipRule="evenodd"
      {...props}
    >
      <g>
        <path d="M2741 1378h-740v371h866v-249c0-66-56-122-126-122zm-1735 0c-70 0-126 56-126 122v249h869v-371h-743zm0 497v866c0 70 56 126 123 126h620v-992h-743zm1612 992c70 0 123-56 123-126v-866h-740v992h617zm-746-1616h497c172 0 249-80 249-185 0-102-77-186-249-186-161 0-287 73-371 158-39-25-81-36-130-36-42 0-84 11-122 32-85-84-211-154-368-154-172 0-249 84-249 186 0 105 77 185 249 185h494zm624-185c0 52-70 63-127 63h-280c-3 0-3-4-3-4 63-63 157-119 283-119 57 0 127 10 127 60zm-1245 0c0-50 70-60 127-60 122 0 217 53 280 112-4 4-4 7-7 11h-273c-57 0-127-11-127-63z" />
        <path
          d="M1875 0c516 0 985 210 1322 550 340 340 550 806 550 1325 0 516-210 985-550 1322-337 340-806 550-1322 550-519 0-988-210-1325-550C210 2860 0 2391 0 1875c0-519 210-988 550-1325C887 210 1356 0 1875 0zm1101 771c-284-280-673-452-1101-452-431 0-820 172-1100 456-284 280-456 669-456 1100 0 428 172 817 452 1101 284 280 673 456 1104 456 428 0 817-176 1101-456 280-284 456-673 456-1101 0-431-176-820-456-1104z"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default GiftIcon;
