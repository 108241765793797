import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useUser from 'src/hooks/useUser';
import { Box, Button, Typography, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { subscribe } from 'src/services/users';
import Confetti from 'src/components/Confetti';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: '#51B8E9',
    color: theme.palette.background.default,
    [theme.breakpoints.up('sm')]: { height: 160 },
    [theme.breakpoints.down('lg')]: { height: 180 },
    position: 'relative',
    bottom: theme.spacing(10),
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.main,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  grow: {
    [theme.breakpoints.up('sm')]: { flexGrow: 1 }
  }
}));

function Subscription({ setSubscriptionState }) {
  const classes = useStyles();
  const history = useHistory();
  const { user, setIsSubscribed } = useUser();
  const { t } = useTranslation();

  const handleSubscription = () => {
    if (user) {
      subscribe({ subscription: 'promo_recargas' }).then(async (response) => {
        if (response.status < 400) {
          setIsSubscribed(true);
          setInterval(async () => {
            setSubscriptionState(false);
          }, 3000);
        }
      });
    } else {
      history.push('/login');
    }
  };

  return (
    <Box component={Paper} className={classes.paper}>
      {!user || (user && !user.isSubscribed) ? (
        <>
          <Box ml={5}>
            <Typography variant="h3" component="h5">
              {t('Get the latest promotions')}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              {t(
                'Be the first to know about exclusive discounts, promotions and special offers.'
              )}
            </Typography>
          </Box>
          <Box className={classes.grow} />
          <Button
            className={classes.button}
            variant="contained"
            component="a"
            size="large"
            onClick={handleSubscription}
          >
            {t('Subscribe Now')}
          </Button>
        </>
      ) : (
        <>
          <Box mt={3}>
            <Typography variant="h3">{t('Thanks for subscribing')}</Typography>
          </Box>
          <Confetti />
        </>
      )}
    </Box>
  );
}

Subscription.propTypes = {
  setSubscriptionState: PropTypes.func
};

export default Subscription;
