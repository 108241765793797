import React, { useRef } from 'react';
import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import MoonIcon from '@mui/icons-material/DarkModeOutlined';
import SunIcon from '@mui/icons-material/WbSunnyOutlined';
import useSettings from 'src/hooks/useSettings';
import { useTranslation } from 'react-i18next';

function Settings() {
  const ref = useRef(null);
  const { t } = useTranslation();
  const { settings, saveSettings } = useSettings();

  const handleChangeTheme = () => {
    const th = settings.theme === 'LIGHT' ? 'ONE_DARK' : 'LIGHT';
    saveSettings({ theme: th });
  };

  return (
    <>
      <Tooltip title={t('Theme')}>
        <IconButton
          color="primary"
          onClick={handleChangeTheme}
          ref={ref}
          size="large"
        >
          <SvgIcon fontSize="small">
            {settings.theme === 'LIGHT' ? <MoonIcon /> : <SunIcon />}
          </SvgIcon>
        </IconButton>
      </Tooltip>
    </>
  );
}

export default Settings;
